<script>
    import { SvelteToast } from '@zerodevx/svelte-toast'
    import { failure } from '../lib/toast.js'

    let email = ''
    let password = ''
    let busy = false
    let show_password = false
    let type = 'password'

    const toogle_show_password = () => {
        show_password = !show_password
        if (type == 'password') {
            type = 'text'
        } else {
            type = 'password'
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        busy = true
        try {
            const resp = await fetch('/api/login', {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            })
            if (resp.status == 200) {
                window.location.href = '/'
            } else {
                const obj = await resp.json()
                if (obj.error) {
                    failure(obj.error)
                }
            }
        } catch (e) {
            failure(e.toString())
        } finally {
            busy = false
        }

        return false
    }
</script>

<div class="relative flex flex-col justify-center h-screen overflow-hidden p-4">
    <div class="w-full p-6 m-auto bg-white rounded-md shadow-md ring-2 ring-gray-800/50 sm:max-w-sm">
        <h1 class="text-3xl font-semibold text-center text-gray-700">FVET Comunidad</h1>
        <form class="space-y-4" on:submit={handleSubmit}>
            <div>
                <label class="label" for="email">
                    <span class="text-base label-text">Correo electrónico</span>
                </label>
                <input
                    id="email"
                    type="text"
                    placeholder="Correo electrónico"
                    class="w-full input input-bordered"
                    bind:value={email}
                />
            </div>
            <div>
                <label class="label" for="password">
                    <span class="text-base label-text">Contraseña</span>
                </label>
                <div class="relative">
                    <input
                        id="password"
                        {...{ type }}
                        placeholder="Contraseña"
                        class="w-full input input-bordered"
                        bind:value={password}
                    />
                    <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                        on:click={toogle_show_password}
                    >
                        {#if show_password}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                            </svg>
                        {:else}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                />
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                />
                            </svg>
                        {/if}
                    </div>
                </div>
            </div>
            <a href="/forgotpass" class="text-xs text-gray-600 hover:underline hover:text-blue-600">
                Recuperar contraseña
            </a>
            <div>
                <button class="btn btn-block btn-primary">
                    {#if busy}
                        <span class="loading loading-spinner loading-xs"></span>
                    {/if}
                    Ingresar
                </button>
            </div>
        </form>
    </div>
</div>

<SvelteToast />
